@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  background-color: #210525;
  overflow-x: hidden;
}

.contato-menu a {
  text-align: center;
  background-color: #a30fb9;
  width: 250px;
  padding: 0px 150px;
  border-radius: 10px 10px 10px 50px;
}
.container {
  width: 1580px;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.navBar .col-md-3 {
  z-index: 7;
}
ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
}
ul.main-menu > li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.menu-item {
  line-height: 23px;
}
li {
  list-style-type: none;
}
.menu-item a {
  font-size: 17px;
  line-height: 40px;
  position: relative;
  display: block;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  padding: 0 27px;
}

.menu-item.menu__qqqitem a {
  margin: 27px 10px;
}

.header__middle {
  display: flex;
  width: 100%;
  position: relative;
  margin-top: 20px;
}
.header__middle__logo > a > h1 {
  color: #07a8ff;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 33px;
}

.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 14px;
  color: #ff1800;
}
.sub__menus__arrows {
  position: relative;
}
.sub__menus__arrows svg,
.sub__menus__arrows__full svg {
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid lavender;
  left: 25px;
  top: 40px;
  padding: 5px 20px;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
  width: 200px;
}

.sub-item-menu a {
  color: black;
}

.sub-item-menu a:hover {
}
@media (min-width: 992px) {
  .sub__menus__arrows:hover .sub__menus {
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus {
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus a {
  padding: 7px 0 0px 0;
  margin: 7px 5px;
}

/* .menu-item:hover > a {
  color: grey !important;
} */
/* .menu-item .sub__menus li:hover a {
  color: grey !important;
} */

.header__middle__logo img {
  width: 80px;
}

@media (max-width: 991px) {
  .contato-menu a {
    width: 90%;
  }
  .cont .header__middle__logo {
    width: 77%;
  }
  .header__middle__menus {
    width: 20%;
  }
  .header__middle__logo img {
    max-width: 197px;
    margin: 27px 0 0 0;
  }
  /* .main-nav .menu-item:hover > a {
    color: grey !important;
  } */
  .main-nav .menubar__button {
    display: block !important;
    float: right;
    color: #fff;
    padding: 6px 7px;
    border-radius: 5px;
    margin: 27px auto 0;
    cursor: pointer;
    position: relative;
    z-index: 10037;
  }
  .main-nav .menubar__button svg {
    font-size: 27px;
  }
  .main-nav ul.menuq2 {
    display: block !important;
    position: absolute;
    left: 0;
    z-index: 10007;
    background-color: #330f39;
    right: 0;
    padding-bottom: 17px;
    padding-top: 57px;
  }
  .main-nav .menu-item a {
    margin: 10px 17px;
    text-align: center;
    color: #ffffff;
  }
  .main-nav ul.main-menu {
    display: none;
  }
  .main-nav .sub__menus__arrows:hover .sub__menus {
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .sub__menus__Active {
    display: block !important;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .sub__menus__Active2 {
    display: block !important;
  }
  .menus__categorysss {
    display: none;
  }
  .menus__categorysss2 {
    display: none;
  }
  .menu-item .sub__menus__full a {
    text-align: left;
  }
}

/* @font-face {
  font-family: "Pacifico";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto"),
    url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700;900")
      format("woff2");
  font-display: swap;
} */

html {
  scroll-behavior: smooth;
}

h1 {
  font-size: 35px;
}

h2 {
  font-size: 35px;
}

p {
  font-size: 25px;
}
a {
  text-decoration: none;
  color: white;
}

.numeros-container-global .numeros-container-dados p {
  font-size: 35px;
}

.servicos-contato a {
  background-color: #a30fb9;
  padding: 10px 50px;
  border-radius: 10px 10px 10px 50px;
}

.banner-inicial {
  max-width: 1580px;
  height: calc(100vh - 300px);
  margin: 100px auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
}

.banner-inicial h1 {
  color: white;
}

.banner-inicial p {
  text-align: justify;
  color: white;
  width: 90%;
}
.espaco {
  width: 100px;
  height: 5px;
  background-color: #a30fb9;
}

.easy-color {
  color: #a30fb9;
}

.segundo-banner {
  max-width: 1580px;
  margin: 120px auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: white;
  padding: 0 20px;
}

.left {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.right {
  flex: 1;
}

.check-list li {
  font-size: 25px;
  display: flex;
  gap: 15px;
  margin-top: 25px;
}

.numeros-container-global {
  background-color: white;
}

.numeros {
  max-width: 1580px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-position: top;
  background-repeat: no-repeat;
  gap: 30px;
  padding: 0 20px;
  text-align: center;
}

.numeros-container-dados {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-top: 100px;
  flex-wrap: wrap;
}

.detalhes-itens::before {
  content: "";
  width: 650px;
  height: 50px;
  background-image: url(../src/assets/linha-direita.svg);
  background-repeat: no-repeat;
  position: absolute;
  top: 0; /* Coloque o pseudo-elemento no topo */
  right: 0; /* Coloque o pseudo-elemento à direita */
}

.detalhes-itens::after {
  content: "";
  width: 650px;
  height: 50px;
  background-image: url(../src/assets/linha-direita.svg);
  background-repeat: no-repeat;
  position: absolute;
  transform: scaleY(-1) scaleX(-1);
  bottom: -1px; /* Coloque o pseudo-elemento na parte inferior */
  left: 0; /* Coloque o pseudo-elemento à esquerda */
}

.detalhes-itens {
  width: 100%;
  position: relative;
}

.itens .numero-item {
  text-align: center;
  color: #a30fb9;
  font-weight: bold;
}

.itens .numero-nome {
  font-weight: bold;
}

.numeros .space-numeros {
  width: 50%;
  height: 3px;
  background-color: #a30fb9;
}

.numeros .descricao-numeros {
  text-align: center;
  margin-bottom: 100px;
}

.servicos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  margin: 100px auto;
  max-width: 1580px;
}

.detalhes-servico {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  color: white;
}

.detalhes-item {
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 50px;
  gap: 20px 10px;
}

.item {
  height: 150px;
  flex: 0 0 18%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.item:hover {
  background-color: #35023d;
  border-radius: 10px;
  transition: 1s;
}

.item img {
  width: 60px;
}

.item p {
  font-size: 20px;
}

footer {
  background-color: #330f39;
}

.container-footer {
  max-width: 1580px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  color: white;
  padding: 35px;
}

.contato-footer .container-icones .icones {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.contato-footer .container-icones p {
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
}

.logo-footer {
  flex: 1;
}

.logo-footer img {
  width: 70px;
}

.descricao-footer {
  flex: 3;
}

.descricao-footer p {
  font-size: 15px;
}
.contato-footer {
  flex: 1;
}

/* --------------------------------------  varejo e atacado ----------------------------------------*/

#varejo-atacado {
  background-image: url(/src/assets/backgrounds/image-varejo-atacado.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: calc(100vh - 100px);
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#restaurante {
  background-image: url(/src/assets/backgrounds/image-varejo-atacado.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: calc(100vh - 100px);
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#farmacia {
  background-image: url(/src/assets/backgrounds/farmacia.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: calc(100vh - 100px);
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#supermercado {
  background-image: url(/src/assets/backgrounds/supermecardo.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: calc(100vh - 100px);
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#auto-peca {
  background-image: url(/src/assets/backgrounds/auto-peca.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: calc(100vh - 100px);
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#padaria {
  background-image: url(/src/assets/backgrounds/padaria.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: calc(100vh - 100px);
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#distribuidora {
  background-image: url(/src/assets/backgrounds/distribuidora.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: calc(100vh - 100px);
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#material-construcao {
  background-image: url(/src/assets/backgrounds/material-construcao.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: calc(100vh - 100px);
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#otica {
  background-image: url(/src/assets/backgrounds/otica.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: calc(100vh - 100px);
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-segmento {
  margin-bottom: 150px;
}

.image-banner h1 {
  color: white;
  font-size: 50px;
}

.descricao-segmento {
  padding: 50px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: white;
  max-width: 1280px;
  margin: 0 auto;
}

.descricao-segmento p {
  text-align: justify;
  margin-top: 50px;
  font-size: 18px;
}

.itens-segmento {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0px auto 50px auto;
  color: white;
  padding: 0 20px;
  gap: 50px;
}

.item-segmento {
  flex: 1 1 40%;
}

.itens-segmento .titulo-item {
  font-weight: bold;
  font-size: 25px;
  margin: 10px 0px;
}

.itens-segmento .descricao-item {
  font-weight: 100;
  font-size: 20px;
}

.contato-item {
  background-color: white;
  position: relative;
  padding: 0px 20px;
}

.container-contato-item {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1180px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.descricao-contato {
  flex: 1 1 40%;
}

.descricao-contato p {
  font-size: 40px;
  margin-bottom: 25px;
  font-weight: bold;
}

.imagem-contato {
  flex: 1 1 40%;
  display: flex;
  justify-content: end;
}

.contato-link a {
  text-align: center;
  background-color: #a30fb9;
  width: 150px;
  padding: 10px 100px;
  border-radius: 10px 10px 10px 50px;
}

.contato-item::before {
  content: "";
  width: 650px;
  height: 50px;
  background-image: url(../src/assets/linha-direita.svg);
  background-repeat: no-repeat;
  position: absolute;
  top: 0; /* Coloque o pseudo-elemento no topo */
  right: 0; /* Coloque o pseudo-elemento à direita */
}

.contato-item::after {
  content: "";
  width: 650px;
  height: 50px;
  background-image: url(../src/assets/linha-direita.svg);
  background-repeat: no-repeat;
  position: absolute;
  transform: scaleY(-1) scaleX(-1);
  bottom: -1px; /* Coloque o pseudo-elemento na parte inferior */
  left: 0; /* Coloque o pseudo-elemento à esquerda */
}

@media (max-width: 1000px) {
  .image-banner h1 {
    font-size: 25px;
  }

  .descricao-segmento h2 {
    font-size: 25px;
  }

  .descricao-segmento p {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .item-segmento {
    flex: 1 1 100%;
  }

  .item-segmento img {
    width: 70px;
  }

  .item-segmento .titulo-item {
    font-size: 20px;
  }

  .container-contato-item {
    max-width: 100%;
    padding: 50px 0px;
  }
  .imagem-contato {
    flex: 1 1 100%;
    justify-content: center;
  }

  .descricao-contato {
    text-align: center;
    flex: 1 1 100%;
  }

  .contato-item::after {
    width: 200px;
  }

  .contato-item::before {
    width: 200px;
  }

  .descricao-contato p {
    font-size: 30px;
  }
}

/* -------------------------------------- varejo e atacado  --------------------------------------*/

@media (max-width: 1000px) {
  .segundo-banner {
    flex-direction: column-reverse;
    align-items: center;
  }

  .segundo-banner .right img {
    width: 350px;
    height: 400px;
  }

  .servicos {
    flex-direction: column;
  }

  .detalhes-servico {
    width: 100%;
  }

  .detalhes-slide {
    width: 90%;
  }
}

@media (max-width: 800px) {
  .banner-inicial {
    align-items: center;
    text-align: center;
    margin: 100px 0 auto;
  }

  .banner-inicial img {
    width: 30;
  }

  .detalhes-itens::before {
    width: 400px;
  }

  .detalhes-itens::after {
    width: 400px;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 30px;
  }

  p {
    font-size: 20px;
  }

  .check-list li {
    font-size: 20px;
  }
}

@media (max-width: 500px) {
  .segundo-banner {
    margin-top: 0px;
  }
  .segundo-banner img {
    width: 30px;
  }

  .segundo-banner .right img {
    width: 300px;
  }

  #sobre .numero-item {
    font-size: 30px;
  }

  #sobre .numero-nome {
    font-size: 20px;
  }

  .numeros .titulo-numeros {
    font-size: 15px;
  }

  .numeros .descricao-numeros {
    font-size: 18px;
    text-align: center;
  }

  .detalhes-itens::before {
    width: 280px;
  }

  .detalhes-itens::after {
    width: 280px;
  }

  .detalhes-servico {
    width: 100%;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 30px;
  }

  p {
    font-size: 20px;
  }

  .check-list li {
    font-size: 20px;
  }

  .detalhes-item {
    gap: 20px 50px;
  }

  .item img {
    width: 60px;
  }

  .item p {
    font-size: 15px;
  }

  .container-footer {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 50px;
  }
}
